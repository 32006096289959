/* ========================================================================
   Component: preloader
 ========================================================================== */

@import '../../shared/styles/bootstrap/_functions.scss';
@import '../../shared/styles/bootstrap/_variables.scss';
@import '../../shared/styles/bootstrap/_mixins';
@import '../../shared/styles/app/variables';
$preloader-bg: #8c1515;
$preloader-wd: 300px;
$preloader-hg: 50px;
$preloader-bg-cwa: #85cd50;
$preloader-wd-cwa: 100px;
$preloader-hg-cwa: 100px;

/*@noflip*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $preloader-bg;
  background-image: -webkit-linear-gradient(
    90deg,
    $preloader-bg 10%,
    darken($preloader-bg, 5%) 90%
  );
  /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(90deg, $preloader-bg 10%, darken($preloader-bg, 5%) 90%);
  /* FF3.6+ */
  background-image: -ms-linear-gradient(90deg, $preloader-bg 10%, darken($preloader-bg, 5%) 90%);
  /* IE10 */
  background-image: -o-linear-gradient(90deg, $preloader-bg 10%, darken($preloader-bg, 5%) 90%);
  /* Opera 11.10+ */
  background-image: linear-gradient(90deg, $preloader-bg 10%, darken($preloader-bg, 5%) 90%);
  /* W3C */
  z-index: 9999;
  transition: opacity 0.65s;
}

/*@noflip*/

.preloader-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: $preloader-wd;
  height: $preloader-hg;
  margin: auto;
  overflow: auto;
  background-image: url(../../../assets/img/preloader/preloader.empty.png);
  background-size: $preloader-wd $preloader-hg;
}

/*@noflip*/

.preloader-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 10px;
  background-image: url(../../../assets/img/preloader/preloader.full.png);
  background-size: $preloader-wd $preloader-hg;
}

.preloader-cwademo .preloader {
  background-color: $preloader-bg-cwa;
  background-image: -webkit-linear-gradient(
    90deg,
    $preloader-bg-cwa 10%,
    darken($preloader-bg-cwa, 5%) 90%
  );
  /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(
    90deg,
    $preloader-bg-cwa 10%,
    darken($preloader-bg-cwa, 5%) 90%
  );
  /* FF3.6+ */
  background-image: -ms-linear-gradient(
    90deg,
    $preloader-bg-cwa 10%,
    darken($preloader-bg-cwa, 5%) 90%
  );
  /* IE10 */
  background-image: -o-linear-gradient(
    90deg,
    $preloader-bg-cwa 10%,
    darken($preloader-bg-cwa, 5%) 90%
  );
  /* Opera 11.10+ */
  background-image: linear-gradient(
    90deg,
    $preloader-bg-cwa 10%,
    darken($preloader-bg-cwa, 5%) 90%
  );
}

.preloader-cwademo .preloader-progress {
  width: $preloader-wd-cwa;
  height: $preloader-hg-cwa;
  background-size: $preloader-wd-cwa $preloader-hg-cwa;
  background-image: url(../../../assets/img/preloader/cwapreloader.empty.png);
}

.preloader-cwademo .preloader-progress-bar {
  background-size: $preloader-wd-cwa $preloader-hg-cwa;
  background-image: url(../../../assets/img/preloader/cwapreloader.full.png);
}

.preloader-hidden {
  display: none;
}

// ngAnimate behavior
.preloader-hidden-add {
  opacity: 1;
  display: block;
  .preloader-progress {
    transition: transform 0.4s ease;
    transform: scale(0);
  }
}

.preloader-hidden-add-active {
  opacity: 0;
}
