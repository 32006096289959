.md-welcome {
    margin: -20px;
    margin-bottom: 0;
    .md-welcome-msg {
        background-color: $primary;
        // background-image: url('assets/img/welcome.jpg');
        background-position: 0 75%;
        color: #fff;
        padding: 80px 30px;
        margin-bottom: 40px;
        h1 {
            color: #fff;
            font-size: 56px;
            font-weight: 400;
            line-height: 1.5;
            margin: 0
        }
        h2 {
            font-weight: 300;
            margin: 0;
        }
    }
}

.md-welcome-row {
    margin-top: 40px;
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
        margin-top: 80px;
        margin-bottom: 80px;
    }
}